import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import PromoCards from 'components/Products/USB-Webcams/IN-W2/PromoCards';
import SpecTable from 'components/Products/USB-Webcams/IN-W2/SpecTable';
import NavButtons from 'components/Products/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-W2 Full-HD 1080P Webcam",
  "path": "/Products/USB-Webcams/IN-W2/",
  "dateChanged": "2021-01-29",
  "author": "Mike Polinowski",
  "excerpt": "The IN-W2 is a USB Webcam with a 1080p Full HD resolution. It can be connected to your PC or Laptop and be used with chat and team programs like Skype, Zoom, Facetime and others.",
  "image": "./P_SearchThumb_IN-W2.png",
  "social": "/images/Search/P_SearchThumb_IN-W2.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-W1_white.webp",
  "chapter": "Products"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='IN-W2 Full-HD 1080P Webcam' dateChanged='2021-01-29' author='Mike Polinowski' tag='INSTAR IP Camera' description='The IN-W2 is a USB Webcam with a 1080p Full HD resolution. It can be connected to your PC or Laptop and be used with chat and team programs like Skype, Zoom, Facetime and others.' image='/images/Search/P_SearchThumb_IN-W2.png' twitter='/images/Search/P_SearchThumb_IN-W2.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Produkte/USB-Webcams/IN-W2/' locationFR='/fr/Products/USB-Webcams/IN-W2/' crumbLabel="IN-W2 Webcam" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "usb-webcams",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#usb-webcams",
        "aria-label": "usb webcams permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USB-Webcams`}</h1>
    <h2 {...{
      "id": "in-w2-full-hd-1080p-webcam",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-w2-full-hd-1080p-webcam",
        "aria-label": "in w2 full hd 1080p webcam permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-W2 Full-HD 1080P Webcam`}</h2>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#overview"
        }}>{`Overview`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#installation"
        }}>{`Installation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#technical-specifications"
        }}>{`Technical Specifications`}</a></li>
    </ul>
    {/* /TOC */}
    <h3 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h3>
    <p>{`The IN-W2 is a 1080p full HD webcam with lens cover that plugs into a USB 2.0 port on your PC or laptop. Thanks to simple plug & play, you don't need to install any software. The webcam works with Windows, macOS and Linux. Simply connect and use your video conferencing software of choice such as Zoom, Skype, Google Hangouts, Facetime and others.`}</p>
    <p>{`With the new Full HD sensor, you get twice the resolution of the sensor used in a 720p HD camera model. Thanks to the extra 1 million pixels, you can see even distant objects in full detail.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <PromoCards mdxType="PromoCards" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#installation",
        "aria-label": "installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installation`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/87dfbbb4b00a2e6b4d5c814356ef49c2/51800/INSTAR_IN-W1_USB_Webcam_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABDElEQVQY03WR0WrDMAxF+/8fVsbCGEsH3UYe2rLabWJbji3HUmM7I80YdOvug0BCB4l7VzHG8aqccyllrmMiouEqRAwhjH+UUooxrph5mqaplHk1hOg9a33Y7Zqmac9nKYQxZronZv6GKcbXupZSPlePdVV1StX1S2/tEAIieu+XagEsQMn5BkbEp6pSXfe23X58vIMxD+u11nqGvQ+Iru+dc73SJyHSLzjnbIyWQpyk7LpW7PfwefS9MwBaa2vt/B2RPBysMaWUG3jpe2sBwLYtCDENQ3c8bjabpmm8c6UUIgIARLwPa6VOcj6ulFqGl8slpfSvYUT0EwAzh6tDzLzkkXNOKY33sqIYvwANJcn5rve5MgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/87dfbbb4b00a2e6b4d5c814356ef49c2/e4706/INSTAR_IN-W1_USB_Webcam_01.avif 230w", "/en/static/87dfbbb4b00a2e6b4d5c814356ef49c2/d1af7/INSTAR_IN-W1_USB_Webcam_01.avif 460w", "/en/static/87dfbbb4b00a2e6b4d5c814356ef49c2/7f308/INSTAR_IN-W1_USB_Webcam_01.avif 920w", "/en/static/87dfbbb4b00a2e6b4d5c814356ef49c2/456c2/INSTAR_IN-W1_USB_Webcam_01.avif 1196w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/87dfbbb4b00a2e6b4d5c814356ef49c2/a0b58/INSTAR_IN-W1_USB_Webcam_01.webp 230w", "/en/static/87dfbbb4b00a2e6b4d5c814356ef49c2/bc10c/INSTAR_IN-W1_USB_Webcam_01.webp 460w", "/en/static/87dfbbb4b00a2e6b4d5c814356ef49c2/966d8/INSTAR_IN-W1_USB_Webcam_01.webp 920w", "/en/static/87dfbbb4b00a2e6b4d5c814356ef49c2/31d32/INSTAR_IN-W1_USB_Webcam_01.webp 1196w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/87dfbbb4b00a2e6b4d5c814356ef49c2/81c8e/INSTAR_IN-W1_USB_Webcam_01.png 230w", "/en/static/87dfbbb4b00a2e6b4d5c814356ef49c2/08a84/INSTAR_IN-W1_USB_Webcam_01.png 460w", "/en/static/87dfbbb4b00a2e6b4d5c814356ef49c2/c0255/INSTAR_IN-W1_USB_Webcam_01.png 920w", "/en/static/87dfbbb4b00a2e6b4d5c814356ef49c2/51800/INSTAR_IN-W1_USB_Webcam_01.png 1196w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/87dfbbb4b00a2e6b4d5c814356ef49c2/c0255/INSTAR_IN-W1_USB_Webcam_01.png",
              "alt": "IN-W1 Full-HD 1080P Webcam",
              "title": "IN-W1 Full-HD 1080P Webcam",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5d8973ce8b19c11cd69b6e4a9f80679b/19a6b/INSTAR_IN-W1_USB_Webcam_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "29.565217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA0klEQVQY04WQy26DMBBF+f9Pi0qARLhRqvBYIGKrBGzkV2bsqcBqWqmLnt09mruYm0kpjTEA4HdijOM43scREZ/fhBC01rjjvU/SOZcxxtq2JaIQAhFprS/vl243MUYiAoDHNJXHou86pdTLI2ImpUxqXde+7+u6ZowJIV5HwzAUeX4qy3NVFXnuvaedrQwAKczz/HG9Cs7P1altmiRDCHKRn0K8HQ7N7faYJmftTxkRU/DeAwDnXElFf0hP/WYrW2vTANtsiMaYZVmcc8//sNZ+AR0RV104xmNmAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5d8973ce8b19c11cd69b6e4a9f80679b/e4706/INSTAR_IN-W1_USB_Webcam_02.avif 230w", "/en/static/5d8973ce8b19c11cd69b6e4a9f80679b/d1af7/INSTAR_IN-W1_USB_Webcam_02.avif 460w", "/en/static/5d8973ce8b19c11cd69b6e4a9f80679b/7f308/INSTAR_IN-W1_USB_Webcam_02.avif 920w", "/en/static/5d8973ce8b19c11cd69b6e4a9f80679b/8b32a/INSTAR_IN-W1_USB_Webcam_02.avif 1191w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5d8973ce8b19c11cd69b6e4a9f80679b/a0b58/INSTAR_IN-W1_USB_Webcam_02.webp 230w", "/en/static/5d8973ce8b19c11cd69b6e4a9f80679b/bc10c/INSTAR_IN-W1_USB_Webcam_02.webp 460w", "/en/static/5d8973ce8b19c11cd69b6e4a9f80679b/966d8/INSTAR_IN-W1_USB_Webcam_02.webp 920w", "/en/static/5d8973ce8b19c11cd69b6e4a9f80679b/812c2/INSTAR_IN-W1_USB_Webcam_02.webp 1191w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5d8973ce8b19c11cd69b6e4a9f80679b/81c8e/INSTAR_IN-W1_USB_Webcam_02.png 230w", "/en/static/5d8973ce8b19c11cd69b6e4a9f80679b/08a84/INSTAR_IN-W1_USB_Webcam_02.png 460w", "/en/static/5d8973ce8b19c11cd69b6e4a9f80679b/c0255/INSTAR_IN-W1_USB_Webcam_02.png 920w", "/en/static/5d8973ce8b19c11cd69b6e4a9f80679b/19a6b/INSTAR_IN-W1_USB_Webcam_02.png 1191w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5d8973ce8b19c11cd69b6e4a9f80679b/c0255/INSTAR_IN-W1_USB_Webcam_02.png",
              "alt": "IN-W1 Full-HD 1080P Webcam",
              "title": "IN-W1 Full-HD 1080P Webcam",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7278ccc1e61ed2e63bd1ce1bdb62489e/187fa/INSTAR_IN-W1_USB_Webcam_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABTklEQVQY0zWQ0XaDIAyGff836vYA7dxcd9OztkiPrtUpSFREQoGWzbF+J+QiP3+Sk6RpmqqqwoPb7Xb/wz9wzjlrvfdRdQ8QMXlN05f15mot5xwAZqWWmOf4z1obm1prhRBa6xDC/X6PlYQx1rbt5Xyu67oHkHL8ruthGEMIep611t77YRjmee4BlFKotXMuhHC9XhNEnKapLBYAoOu6HgARQwiotRzHScqyKHJCRCe6bnlSyn9z27acMc7Yfv95ojRW43rGGMZY3/c9ADmSw34fQvDeK6X+zSBEx3me5yd6OlF6/jp/bLfZ25sxBhFBCAFQFgU5HsuyLMpyt9tt1hsp5WK21hpjLpfL8XB4TdPn1dN7llFKOefjMCCic66uKkpIlmVPq1WapjkhTdNIKRNjzO0PY4wQQikVs/feIGqtoxqvraZFnaR0zv1O/gGHab/5BJr2NQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7278ccc1e61ed2e63bd1ce1bdb62489e/e4706/INSTAR_IN-W1_USB_Webcam_03.avif 230w", "/en/static/7278ccc1e61ed2e63bd1ce1bdb62489e/d1af7/INSTAR_IN-W1_USB_Webcam_03.avif 460w", "/en/static/7278ccc1e61ed2e63bd1ce1bdb62489e/7f308/INSTAR_IN-W1_USB_Webcam_03.avif 920w", "/en/static/7278ccc1e61ed2e63bd1ce1bdb62489e/e78a4/INSTAR_IN-W1_USB_Webcam_03.avif 1194w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7278ccc1e61ed2e63bd1ce1bdb62489e/a0b58/INSTAR_IN-W1_USB_Webcam_03.webp 230w", "/en/static/7278ccc1e61ed2e63bd1ce1bdb62489e/bc10c/INSTAR_IN-W1_USB_Webcam_03.webp 460w", "/en/static/7278ccc1e61ed2e63bd1ce1bdb62489e/966d8/INSTAR_IN-W1_USB_Webcam_03.webp 920w", "/en/static/7278ccc1e61ed2e63bd1ce1bdb62489e/de1dc/INSTAR_IN-W1_USB_Webcam_03.webp 1194w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7278ccc1e61ed2e63bd1ce1bdb62489e/81c8e/INSTAR_IN-W1_USB_Webcam_03.png 230w", "/en/static/7278ccc1e61ed2e63bd1ce1bdb62489e/08a84/INSTAR_IN-W1_USB_Webcam_03.png 460w", "/en/static/7278ccc1e61ed2e63bd1ce1bdb62489e/c0255/INSTAR_IN-W1_USB_Webcam_03.png 920w", "/en/static/7278ccc1e61ed2e63bd1ce1bdb62489e/187fa/INSTAR_IN-W1_USB_Webcam_03.png 1194w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7278ccc1e61ed2e63bd1ce1bdb62489e/c0255/INSTAR_IN-W1_USB_Webcam_03.png",
              "alt": "IN-W1 Full-HD 1080P Webcam",
              "title": "IN-W1 Full-HD 1080P Webcam",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`For more information check out our `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/IN-W1_USB_Webcam/"
      }}>{`FAQ Section`}</a>{`.`}</p>
    <h2 {...{
      "id": "technical-specifications",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#technical-specifications",
        "aria-label": "technical specifications permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Technical Specifications`}</h2>
    <SpecTable mdxType="SpecTable" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      